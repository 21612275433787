
import { LazyLoadImage, ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component'

import Link from '../../../../../../../Link/Link'
import Icon from '../../../../../../../Icon'

import * as Styled from './LazyLoadGallery.style'

import { TracksState, TracksProps } from '../../../../Track.types'

interface LazyLoadGaller {
  images: TracksState['allImages']
  updatePhotoLibraryData: TracksProps['updatePhotoLibraryData']
  scrollPosition: ScrollPosition
}

const LazyLoadGallery = ({ images, scrollPosition, updatePhotoLibraryData }: LazyLoadGaller) => (
  <>
    {images.map((image, key: number) => {
      return (
        <Styled.PhotoWrapper key={key}>
          <Styled.DeletePhoto>
            <Icon
              name="close"
              size={1}
              // onClick={deletePhoto}
            />
          </Styled.DeletePhoto>
          <Link
            key={key}
            onClick={() => {
              updatePhotoLibraryData(
                images,
                key,
                true,
              )
            }}
          >
            <Styled.Photo>
              <LazyLoadImage
                alt={image.name}
                src={image.url}
                scrollPosition={scrollPosition}
              />
            </Styled.Photo>
          </Link>
        </Styled.PhotoWrapper>
      )
    })}
  </>
)

export default trackWindowScroll(LazyLoadGallery)
