

import React from 'react'
import PropTypes from 'prop-types'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import * as Styled from './Link.style'

class Input extends React.Component {
  render() {
    return (
      <Styled.Link
        onClick={this.props.onClick}
        href={this.props.url}
        isUnderlined={this.props.isUnderlined}
        target={this.props.target}
      >
        <Choose>
          <When condition={this.props.text}>{this.props.text}</When>
          <Otherwise>{this.props.children}</Otherwise>
        </Choose>
      </Styled.Link>
    )
  }
}

Input.propTypes = {
  // OnClick
  onClick: PropTypes.func,
  // URL - https to open
  url: PropTypes.string,
  // IsUnderlined - styling
  isUnderlined: PropTypes.bool,
  // Target
  target: PropTypes.string,
  // Text
  text: PropTypes.string,
}

Input.defaultProps = {
  target: '_blank',
  isUnderlined: false,
}

export default Input
