
import { Component } from 'react'
import { If, Choose, When, Otherwise } from 'jsx-control-statements'

import LoadingAnimation from '../../../../../LoadingAnimation/LoadingAnimation'
import PlaceholderPhoto from '../components/PlaceholderPhoto/PlaceholderPhoto'
import LazyLoadGallery from './Components/LazyLoadGallery/LazyLoadGallery'

import * as Styled from './TrackPhotos.style'

class TrackPhotos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      photoKey: null,
    }
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    })
  }

  render() {
    return (
      <Styled.PhotosWrapper>
        <Styled.Header>
          <h5>Photos</h5>
        </Styled.Header>
        <Choose>
          <When condition={this.props.uploadingImages === true}>
            <Styled.ProgressBar>
              <LoadingAnimation />
              {`${this.props.uploadImageCount} of ${
                this.props.imagesToUpload
              } photos uploaded`}
            </Styled.ProgressBar>
          </When>
          <Otherwise>
          <Styled.Content>
            <PlaceholderPhoto onChange={(e) => uploadImages(e)} />
            <If condition={this.props.images}>
              <LazyLoadGallery
                updatePhotoLibraryData={this.props.updatePhotoLibraryData}
                images={this.props.images}
              />
            </If>
          </Styled.Content>
          </Otherwise>
        </Choose>
      </Styled.PhotosWrapper>
    )
  }
}

export default TrackPhotos
