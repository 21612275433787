
import React, { Component } from 'react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { If } from 'jsx-control-statements'

import Input from '../../../../../Input/Input'
import APIController from '../helpers/APIController/APIController'

import { TrackData } from '../../Track.types'

import * as Styled from './SongSearchBar.style'

interface AlbumImage {
  url: string
  height: number
  width: number
}

interface SpotifyTrack {
  artists: {
    name: string
  }[]
  album: {
    images?: AlbumImage[]
  }
  name: string
  external_urls: {
    spotify: string
  }
}

interface SongSearchBarProps {
  tracks: TrackData[]
  userId: string
  currentTrackId: string
}

class SongSearchBar extends Component<SongSearchBarProps> {
  firestore: any

  constructor(props) {
    super(props)
    this.firestore = getFirestore()
  }

  state = {
    playlist: [],
    showTrackSearchResults: true,
  }

  spotifyDetails = APIController()
  token = ''

  async componentDidMount() {
    this.token = await this.spotifyDetails.getToken()

    if (this.props.accessId) {
      this.setState({
        accessId: this.props.accessId,
      })
    }
  }

  handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const searchValue = event.target.value
    let playlist = []

    if (searchValue.length > 0) {
      const tracksEndpoint = `https://api.spotify.com/v1/search?q=${searchValue}&type=track&include_external=audio`
      playlist = await this.spotifyDetails.getTracks(this.token, tracksEndpoint)
    }

    this.setState({
      playlist,
    })
  }

  handleOnFocus = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    this.setState({
      showTrackSearchResults: true,
    }, async () => {
      await this.handleOnChange(event)
    })
  }

  fetchAlbumArt = (track: SpotifyTrack): AlbumImage | null => {
    if (!track.album.images) return null
    const image = track.album.images.reduce((lastImage: AlbumImage, currentImage: AlbumImage): AlbumImage => {
      if (currentImage.width <= lastImage.width) {
        lastImage.url = currentImage.url
      }
      return lastImage
    }, {
      width: 640,
      height: 640,
      url: ''
    })

    return image
  }

  handleOnClick = async (externalUrl: string, songDetails: string, e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault()
    const allTracks = this.props.tracks
    
    for (const track of allTracks) {
      if (track.id === this.props.currentTrackId) {
        const iframeUrl = await this.spotifyDetails.getIframeUrl(externalUrl)

        const updatedPlaylist = [
          ...track.playlist,
          {
            externalUrl,
            songDetails,
            iframeUrl
          }
        ]

        track.playlist = updatedPlaylist
      }
    }

    const docRef = doc(this.firestore, `users/${this.props.userId}`)

    try {
      await updateDoc(docRef, {
        tracks: allTracks,
      })
      this.setState({
        showTrackSearchResults: false,
      })
      console.log('update successful')
    } catch(error) {
      console.log('update unsuccessful', error)
    }
  }

  render() {
    console.log('this.state.accessId', this.state.accessId)
    return (
      <Styled.SongSearchBarWrapper>
        <Styled.InputWrapper>
          <Input
            name='spotify song search'
            placeholder='Search for the songs you played to death on this trip...'
            onChange={this.handleOnChange}
            onFocus={this.handleOnFocus}
          />
        </Styled.InputWrapper>
        <If condition={this.state.playlist.length && this.state.showTrackSearchResults}>
          <Styled.TrackSelectWrapper>
            {this.state.playlist.map((song: SpotifyTrack, key) => {
              const songDetails = `${song.name}, ${song.artists[0].name}`
              const albumArt = this.fetchAlbumArt(song)
              const externalUrl = song.external_urls.spotify
              return (
                <Styled.TrackSelect
                  onClick={async (e) => await this.handleOnClick(externalUrl, songDetails, e)}
                  key={key}
                >
                  <Styled.AlbumArt src={albumArt?.url} />
                  {songDetails}
                </Styled.TrackSelect>
              )
            })}
          </Styled.TrackSelectWrapper>
        </If>
      </Styled.SongSearchBarWrapper>
    )
  }
}

export default SongSearchBar


