
import { Component } from 'react'

import { icons } from '../../../../Assets/Icons'

import * as Styled from './Pin.style'

interface PinProps {
  // FilterId - ID of the track (check if this is needed when upgraded to styled components)
  filterId: string
  // onClick passed from WorldMap to open track
  onClick: () => {}
  // the scale used to control pin width/height as based on the zoom level of the map
  scale: number
}

class Pin extends Component<PinProps> {
  render() {
    return (
      <Styled.PinWrapper
        value={this.props.filterId}
        onClick={this.props.onClick}
      >
        <Styled.PinIcon
          src={`${icons.pin}`}
          scale={this.props.scale}
        />
      </Styled.PinWrapper>
    )
  }
}

export default Pin
