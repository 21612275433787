import styled, { css } from 'styled-components'
// import { colors } from '../../../../../Assets/css-globals'

export const LibraryWrapper = styled.div(() => {
  return css`
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `
})

export const CloseButton = styled.div(() => {
  return css`
    position: absolute;
    top: 24px;
    right: 24px;
  `
})

export const PhotoLibrary = styled.div(() => {
  return css`
    width: 100%;
    height: 100%;
    padding: 80px;
    box-sizing: border-box;
    background-color: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
  `
})

export const PhotoWrapper = styled.div(() => {
  return css`
    height: calc(100% - 35px);
    width: 100%;
    max-width: 1100px;
    display: flex;
  `
})

export const PrevPhoto = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  `
})

export const NextPhoto = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  `
})

export const Photo = styled.div(() => {
  return css`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #222;

    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: scale-down;
    }
  `
})

export const PhotoCount = styled.div(() => {
  return css`
    display: flex;
    height: 35px;
    font-size: 12px;
    align-items: center;
  `
})
