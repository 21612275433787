
import React from 'react'

interface SpotifyEmbedProps {
  iframeUrl: string
}

class SpotifyEmbed extends React.Component<SpotifyEmbedProps> {
  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.iframeUrl }} />
    )
  }
}

export default SpotifyEmbed
