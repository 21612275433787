import styled, { css } from 'styled-components'

import pointer from '../../Assets/Icons/pointer.svg'

interface MapWrapperProps {
  isActive: boolean
}
export const MapWrapper = styled.div((props: MapWrapperProps) => {
  return css`
    height: calc(100% - 70px);
    position: relative;

    // TODO how to use pointer on google map
    ${props.isActive && css`
      .gm-style:first-of-type > div:nth-child(1) {
        cursor: url(${pointer}) 20 20, auto !important;
      }
    `}
  `
})

export const AddTrackButton = styled.div(() => {
  return css`
    position: fixed;
    z-index: 1;
    right: 0;
    margin-right: 18px;
  `
})
