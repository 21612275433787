
import styled, { css } from 'styled-components'

import { colors } from '../../Assets/css-globals'

export const LoadingScreen = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.primary};
    height: 100vh;
  `
})
