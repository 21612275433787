
import React from 'react'
import {
  getFirestore,
  getDoc,
  setDoc,
  updateDoc,
  doc,
  Firestore,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import GoogleMapReact from 'google-map-react'
import { If } from 'jsx-control-statements'

import Button from '../../Components/Button/Button'
import PhotoLibraryModal from '../../Components/PhotoLibraryModal/PhotoLibraryModal'
import Pin from './Components/Pin'
import Track from './Components/Track'

import mapStyles from './GoogleMapStyles'
import * as Styled from './WorldMap.style'

class WorldMap extends React.Component {
  API_KEY: string
  firestore: Firestore

  constructor(props) {
    super(props)
    this.state = {
      tracksArray: [],
      photos: [],
      photoKey: null,
      filteredTrack: null,
      openTrack: false,
      openPhotoLibrary: false,
      addingTrack: false,
    }

    this.API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
    this.firestore = getFirestore()
  }

  componentDidMount() {
    this.fetchData()
  }

  get userId() {
    const currentUser = getAuth().currentUser
    if (!currentUser) return null
    return currentUser.uid
  }

  async fetchData() {
    if (this.userId === null) return null
    try {
      const docRef = doc(this.firestore, `users/${this.userId}`)

      const userDocs = await getDoc(docRef)

      const result = userDocs.data()

      if (result) {
        const { tracks = [] } = result
        this.setState({
          tracksArray: tracks,
        })
      }
    } catch (error) {
      console.log('Error fetching user data: ', error)
    }
  }

  fetchLocationData = async () => {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.lat},${this.state.lng}&key=${this.API_KEY}&result_type=administrative_area_level_2|administrative_area_level_1|country`,
    )
    return res.json()
  }

  formatLocationName = (locationData) => {
    let locationName = ''
    locationData.results.reverse().forEach((element) => {
      if (element.types.includes('administrative_area_level_2')) {
        locationName = element.formatted_address
        return
      }
      if (element.types.includes('administrative_area_level_1')) {
        locationName = element.formatted_address
        return
      }
      if (element.types.includes('country')) {
        locationName = element.formatted_address
        return
      }
    })
    return locationName
  }

  handlePinClick = (e) => {
    const filteredTrack = this.state.tracksArray.filter(
      (track) => e.currentTarget.value === track.id,
    )

    this.setState({
      openTrack: true,
      filteredTrack: filteredTrack[0],
    })
  }

  closeTrack = () => {
    this.setState({
      openTrack: false,
      filteredTrack: null,
    })
  }

  _onClick = ({ lat, lng }) => {
    if (this.state.addingTrack) {
      this.setState({ lat, lng }, () => {
        this.pushTrackDetails()
      })
    }
  }

  pushTrackDetails = async () => {
    const docRef = doc(this.firestore, 'users', `${this.userId}`)
    const locationData = await this.fetchLocationData()
    const formattedLocationName = this.formatLocationName(locationData)

    await updateDoc(docRef, {
      tracks: [
        {
          id: `${this.state.lat}${this.state.lng}`,
          lat: this.state.lat,
          lng: this.state.lng,
          locationName: formattedLocationName,
          firstTime: true,
          notes: {},
          playlist: [],
        },
        ...this.state.tracksArray,
      ],
    })
    await this.fetchData()
    this.setState({
      addingTrack: false,
    })
  }

  addTrack = () => {
    this.setState({
      addingTrack: true,
    })
  }

  updatePhotoLibraryData = (photos, photoKey, openPhotoLibrary) => {
    this.setState({
      photos,
      photoKey,
      openPhotoLibrary,
    })
  }

  closePhotoLibrary = () => {
    this.setState({
      openPhotoLibrary: false,
    })
  }

  updatePinScale = (zoom: number): void => {
    const pinScale = (14 + 4*zoom)
    this.setState({
      pinScale,
    })
  }

  render() {
    return (
      <Styled.MapWrapper isActive={this.state.addingTrack}>
        {this.state.filteredTrack && (
          <Track
            fetchData={this.fetchData}
            closeTrack={this.closeTrack}
            isOpen={this.state.openTrack}
            data={this.state.filteredTrack}
            userId={this.userId}
            tracksArray={this.state.tracksArray}
            updatePhotoLibraryData={this.updatePhotoLibraryData}
          />
        )}
        <Styled.AddTrackButton>
          <Button onClick={this.addTrack} text="Add Track" appearance="alt2" isActive={this.state.addingTrack} />
        </Styled.AddTrackButton>
        <GoogleMapReact
          bootstrapURLKeys={{ key: this.API_KEY }}
          defaultCenter={{ lat: 40, lng: 0 }}
          defaultZoom={0}
          options={{
            fullscreenControl: false,
            styles: mapStyles,
            maxZoom: 8,
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              strictBounds: true,
            },
          }}
          onClick={this._onClick}
          onChange={({ zoom }) => {this.updatePinScale(zoom)}}
        >
          {this.state.tracksArray.map((track, key) => {
            return (
              <Pin
                key={key}
                filterId={track.id}
                lat={track.lat}
                lng={track.lng}
                trackData={track}
                scale={this.state.pinScale}
                onClick={this.handlePinClick}
              />
            )
          })}
        </GoogleMapReact>
        <If condition={this.state.openPhotoLibrary}>
          <PhotoLibraryModal
            photos={this.state.photos}
            photoKey={this.state.photoKey}
            closePhotoLibrary={this.closePhotoLibrary}
          />
        </If>
      </Styled.MapWrapper>
    )
  }
}

export default WorldMap
