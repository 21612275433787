
import styled, { css } from 'styled-components'

export const PageWrapper = styled.div(() => {
  return css`
    height: 100vh;
  `
})

export const BackgroundImage = styled.div((props) => {
  return css`
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props.image});
  `
})

export const LoginCardContainer = styled.div(() => {
  return css`
    height: calc(100% - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
  `
})

export const LoginCard = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    width: 100%;
    max-width: 390px;
    padding: 34px;
  `
})

export const Intro = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `
})

export const TagLine = styled.span(() => {
  return css`
    font-family: 'Averia Libre';
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    margin: 8px 0 14px;
  `
})

export const LogoWrapper = styled.div(() => {
  return css`
    margin: 10px;
  `
})

export const LoginForm = styled.form(() => {
  return css`
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    :nth-child(1n) > div: {
      margin-top: 12px;
    }
    input {
      margin-top: 18px;
    }
  `
})

export const SubText = styled.span(() => {
  return css`
    font-size: 13px;
    margin-top: 8px;
  `
})

export const PasswordLink = styled.span(() => {
  return css`
    margin: 0 5px;
  `
})
