import styled, { css } from 'styled-components'

export const NotesPreviewWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
  `
})

export const Header = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  `
})

export const Content = styled.div((props) => {
  return css`
    height: 50px;
    overflow: hidden;
    font-size: 14px;
    color: #ccc;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `
})
