
import React from 'react'
import { getFirestore, doc, getDoc, collection, query, updateDoc } from 'firebase/firestore'

import Button from '../../../../../Button/Button'

import * as Styled from './TrackNotes.style'

class TrackNotes extends React.Component {
  firestore: any

  constructor(props) {
    super(props)
    this.state = {
      saveButtonText: 'save',
      changesMade: false,
      textValue: this.props.notes.note ? this.props.notes.note : '',
    }

    this.firestore = getFirestore()
  }

  handleTextUpdate = (e) => {
    this.setState({
      saveButtonText: 'save',
      changesMade: true,
      textValue: e.target.value,
    })
  }

  saveChanges = async () => {
    const allTracks = this.props.tracksArray
    // takes the tracksArray, finds the active track, and updates its data
    allTracks.forEach((track) => {
      if (track.id === this.props.data.id) {
        return (track.notes.note = this.state.textValue)
      }
      return track
    })

    const docRef = doc(this.firestore, `users/${this.props.userId}`)

    try {
      await updateDoc(docRef, {
        tracks: allTracks,
      })

      console.log('update successful')
      this.setState({
        saveButtonText: 'saved!',
        changesMade: false,
      })
    } catch(error) {
      console.log('update unsuccessful', error)
      this.setState({
        saveButtonText: 'something went wrong, try again!',
      })
    }
  }

  render() {
    return (
      <Styled.NotesWrapper>
        <Styled.Header>
          <h5>Notes</h5>
        </Styled.Header>
        <Styled.Content>
          <textarea
            placeholder="Jot down some memories from your trip..."
            onChange={this.handleTextUpdate}
            value={this.state.textValue}
          />
          <Styled.SubmitWrapper>
            <Button
              text={this.state.saveButtonText}
              onClick={this.saveChanges}
              disabled={!this.state.changesMade}
            />
          </Styled.SubmitWrapper>
        </Styled.Content>
      </Styled.NotesWrapper>
    )
  }
}

export default TrackNotes
