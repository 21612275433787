

import React from 'react'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import * as Styled from './Button.style'

import Icon from '../Icon'

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onClick = (e) => {
    if (!this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    return (
      <Styled.Button
        onClick={this.props.onClick}
        appearance={this.props.appearance}
        type={this.props.type}
        isActive={this.props.isActive}
        disabled={this.props.disabled}
      >
        <Choose>
          <When condition={this.props.icon?.name}>
            <Styled.ButtonText>{this.props.text}</Styled.ButtonText>
            <Styled.IconWrapper aligned={this.props?.aligned}>
              <Icon name={this.props?.name} size={this.props?.size} />
            </Styled.IconWrapper>
          </When>
          <Otherwise>{this.props.text}</Otherwise>
        </Choose>
      </Styled.Button>
    )
  }
}

// Button.propTypes = {
//   // Appearance - defaults to 'primary'
//   appearance: PropTypes.string,
//   // OnClick - function to call onClick
//   onClick: PropTypes.func,
//   // Type - for form submits, defaults to 'button'
//   type: PropTypes.string,
//   // Text - content string
//   text: PropTypes.string,
//   // Icon - icon to display
//   icon: PropTypes.shape({
//     // Name - icon name
//     name: PropTypes.string,
//     // Aligned - can be 'left' or 'right'
//     aligned: PropTypes.string,
//     // Size - icon size
//     size: PropTypes.number,
//   }),
// }

// Button.defaultProps = {
//   appearance: 'primary',
//   type: 'button',
// }

export default Button
