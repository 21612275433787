import styled, { css } from 'styled-components'

import { colors } from '../../../../../Assets/css-globals'

export const DetailsWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  `
})

export const DetailsContent = styled.div(() => {
  return css`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  `
})

export const ContentNav = styled.div((props: {activePanel: string}) => {
  let activeButton = 1
  if (props.activePanel === 'notes') activeButton = 2
  if (props.activePanel === 'photos') activeButton = 3
  return css`
    position: absolute;
    bottom: 0;

    > button {
      margin-right: 15px;

      &:nth-child(${activeButton}) {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  `
})

export const TitleAndDates = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `
})

export const Dates = styled.span(() => {
  return css`
    margin-top: 5px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    font-size: 13px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${colors.alt2};
  `
})
