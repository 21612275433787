
import { getStorage, ref, listAll, list, getDownloadURL } from 'firebase/storage'

export interface TrackImage {
  url: string
  name: string
}

async function processImages(results) {
  const images = [] as TrackImage[]

  for (const image of results.items) {
    await new Promise((resolve) => {
      getDownloadURL(image)
        .then((url) => {
          images.push({
            url,
            name: image.name,
          })
        })
        .then((imageData) => {
          resolve(imageData)
        })
        .catch((error) => {
          console.log(`something went wrong downloading ${image.name}`, error)
        })
    })
  }

  return images
}

export async function fetchFavouriteImages(userId: string, trackId: string): Promise<TrackImage[]> {
  // image folder ref
  const storage = getStorage()
    
  const imageRefs = ref(storage, `${userId}/${trackId}/`) 

  let images = [] as TrackImage[]

  console.log('fetchFavouriteImages called')

  // list all images inside, get the url for them and push to image array in state
  await list(imageRefs, { maxResults: 3 }).then(async (results) => {
    images = await processImages(results)
  })

  return images
}

export async function fetchAllImages(userId: string, trackId: string): Promise<TrackImage[]> {
  // image folder ref
  const storage = getStorage()
  const imageRefs = ref(storage, `${userId}/${trackId}/`) 

  console.log('fetchAllimages called')

  let images = [] as TrackImage[]

  // list all images inside, get the url for them and push to image array in state
  await listAll(imageRefs).then(async (results) => {
    images = await processImages(results)
  })

  return images
}

