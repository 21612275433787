import styled, { css } from 'styled-components'

import { colors } from '../../../../../../Assets/css-globals'

export const SongSearchBarWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
  `
})

export const InputWrapper = styled.div(() => {
  return css`
    display: flex;

    input {
      width: 100%;
    }
  `
})

export const TrackSelectWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    max-height: 125px;
    overflow: auto;
    // this might have to be conditional depending on where this component is being used
    position: absolute;
    bottom: -53px;
    width: calc(100% - 60px);
  `
})

export const TrackSelect = styled.button(() => {
  return css`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    border: 0;
    border-top: 1px solid ${colors.monochrome3};
    background-color: white;

    :hover {
      cursor: pointer;
      background-color: ${colors.monochrome3};
    }
  `
})

export const AlbumArt = styled.img(() => {
  return css`
    width: 34px;
    height: 34px;
    margin-right: 14px;
  `
})
