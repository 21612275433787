
import React from 'react'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import Link from '../../../../Link/Link'
import PlaceholderPhoto from './components/PlaceholderPhoto/PlaceholderPhoto'
import LoadingAnimation from '../../../../LoadingAnimation/LoadingAnimation'

import * as Styled from './TrackPhotosPreview.style'

class TrackPhotosPreview extends React.Component {
  render() {
    let previewPhotos = [{}, {}, {}]
    return (
      <Styled.PhotosPreviewWrapper>
        <Styled.Header>
          <h5>Photo highlights</h5>
          <Link
            onClick={() => {
              this.props.activatePanel('photos')
            }}
            url="#"
            text="view/edit photos"
            target=""
          />
        </Styled.Header>
        <Styled.Content>
          <Choose>
            <When condition={this.props.downloadingImages}>
              <Styled.LoadingAnimationWrapper>
                <LoadingAnimation />
                Loading images...
              </Styled.LoadingAnimationWrapper>
            </When>
            <When condition={this.props.uploadingImages}>
              <Styled.LoadingAnimationWrapper>
                <LoadingAnimation />
                Uploading image...
              </Styled.LoadingAnimationWrapper>
            </When>
            <Otherwise>
              {previewPhotos.map((photo, key) => {
                return (
                  <Choose>
                    <When condition={this.props.images[key]?.name}>
                      <Link key={key}>
                        <Styled.Photo
                          alt={this.props.images[key].name}
                          src={this.props.images[key].url}
                        />
                      </Link>
                    </When>
                    <Otherwise>
                      <PlaceholderPhoto
                        onChange={(e) => {
                          this.props.uploadImages(e)
                        }}
                        key={key}
                      />
                    </Otherwise>
                  </Choose>
                )
              })}
            </Otherwise>
          </Choose>
        </Styled.Content>
      </Styled.PhotosPreviewWrapper>
    )
  }
}

export default TrackPhotosPreview
