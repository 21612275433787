
import React from 'react'

import Icon from '../Icon/Icon'

import * as Styled from './LoadingAnimation.style'

class LoadingAnimation extends React.Component {
  render() {
    return (
      <Styled.LoadingAnimation>
        <Styled.LeftFoot>
          <Icon name="footLeft" size={1} appearance="secondary" />
        </Styled.LeftFoot>
        <Styled.RightFoot>
          <Icon name="footRight" size={1} appearance="secondary" />
        </Styled.RightFoot>
        <Styled.LeftFoot>
          <Icon name="footLeft" size={1} appearance="secondary" />
        </Styled.LeftFoot>
        <Styled.RightFoot>
          <Icon name="footRight" size={1} appearance="secondary" />
        </Styled.RightFoot>
        <Styled.LeftFoot>
          <Icon name="footLeft" size={1} appearance="secondary" />
        </Styled.LeftFoot>
        <Styled.RightFoot>
          <Icon name="footRight" size={1} appearance="secondary" />
        </Styled.RightFoot>
      </Styled.LoadingAnimation>
    )
  }
}

export default LoadingAnimation
