
import React from 'react'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import Link from '../../../../../Link/Link'

import * as Styled from './TrackNotesPreview.style'

interface TrackNotesPreviewProps {
  notes: {
    note: string
  }
  activatePanel: (panelName: string) => {}
}

class TrackNotesPreview extends React.Component<TrackNotesPreviewProps> {
  render() {
    if (!this.props.notes) return null
    return (
      <Styled.NotesPreviewWrapper>
        <Styled.Header>
          <h5>Notes</h5>
          <Link
            onClick={() => {
              this.props.activatePanel('notes')
            }}
            url="#"
            text="view/edit notes"
            target=""
          />
        </Styled.Header>
        <Styled.Content>
          <span>
            <Choose>
              <When condition={this.props.notes}>{this.props.notes.note}</When>
              <Otherwise>Enter some memories of your trip here...</Otherwise>
            </Choose>
          </span>
        </Styled.Content>
      </Styled.NotesPreviewWrapper>
    )
  }
}

export default TrackNotesPreview
