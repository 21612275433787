import styled, { css } from 'styled-components'

export const LoadingAnimation = styled.div((props) => {
  return css`
    display: flex;
    justify-content: center;
    height: 34px;
    width: 100%;
    margin-bottom: 28px;

    @keyframes step1 {
      15% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
    @keyframes step2 {
      10% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      60% {
        opacity: 0;
      }
    }
    @keyframes step3 {
      20% {
        opacity: 0;
      }
      35% {
        opacity: 1;
      }
      70% {
        opacity: 0;
      }
    }
    @keyframes step4 {
      30% {
        opacity: 0;
      }
      45% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
    }
    @keyframes step5 {
      40% {
        opacity: 0;
      }
      55% {
        opacity: 1;
      }
      90% {
        opacity: 0;
      }
    }
    @keyframes step6 {
      50% {
        opacity: 0;
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    > div:nth-child(1) {
      opacity: 0;
      animation: step1 5s infinite;
    }
    > div:nth-child(2) {
      opacity: 0;
      animation: step2 5s infinite;
    }
    > div:nth-child(3) {
      opacity: 0;
      animation: step3 5s infinite;
    }
    > div:nth-child(4) {
      opacity: 0;
      animation: step4 5s infinite;
    }
    > div:nth-child(5) {
      opacity: 0;
      animation: step5 5s infinite;
    }
    > div:nth-child(6) {
      opacity: 0;
      animation: step6 5s infinite;
    }
  `
})

export const LeftFoot = styled.div((props) => {
  return css`
    display: flex;
    align-items: flex-start;

    > div {
      display: flex;
      margin: 0 8px;
      transform: rotate(90deg);
    }
  `
})

export const RightFoot = styled.div((props) => {
  return css`
    display: flex;
    align-items: flex-end;

    > div {
      display: flex;
      margin: 0 8px;
      transform: rotate(90deg);
    }
  `
})
