type Colors = Record<string, string>

const colors: Colors = {
  primary: '#09ed9f',
  secondary: '#fff',
  alt1: '#333',
  alt2: '#999',
  monochrome3: '#f3f3f3',
  darkBlue: '#1A263A',
}

export { colors }
