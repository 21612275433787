
import React, { Component } from 'react'
import { If, Choose, When, Otherwise } from 'jsx-control-statements'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import get from 'lodash/get'

import Icon from '../../../Icon'
import TrackDetails from './TrackDetails/TrackDetails'
import TrackNotes from './Notes/Main/TrackNotes'
import TrackNotesPreview from './Notes/Preview/TrackNotesPreview'
import TrackPhotos from './Photos/Main/TrackPhotos'
import TrackPhotosPreview from './Photos/TrackPhotosPreview'
import TrackSpotifyPreview from './Spotify/Preview/TrackSpotifyPreview'

import { fetchFavouriteImages, fetchAllImages } from '../../../../Utils'

import * as Styled from './Track.style'

import { TracksProps, TracksState } from './Track.types'

class Track extends Component<TracksProps, TracksState> {
  constructor(props: TracksProps) {
    super(props)
    this.state = {
      activePanel: 'home',
      imagesToUpload: 0,
      uploadImageCount: 0,
      uploadingImages: false,
      downloadingImages: true,
      favImages: [],
      allImages: [],
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escapeTrack, false)
    this.downloadFavImages()
    this.downloadAllImages()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeTrack, false)
  }

  downloadFavImages = async () => {
    this.setState({
      downloadingImages: true,
      favImages: [],
    })
 
    const favImages = await fetchFavouriteImages(this.props.userId, this.props.data.id)

    this.setState({
      downloadingImages: false,
      favImages,
    })
  }

  downloadAllImages = async () => {
    this.setState({
      allImages: [],
    })
 
    const allImages = await fetchAllImages(this.props.userId, this.props.data.id)

    this.setState({
      allImages,
    })
  }

  uploadImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfImagesToUpload = e.target!.files!.length
    this.setState({
      uploadingImages: true,
      uploadImageCount: 0,
      imagesToUpload: numberOfImagesToUpload,
    })

    const files = e.target.files!

    for (let i = 0; i < numberOfImagesToUpload; i++) {
      // get file
      const file = files[i]
      // create firebase storage ref
      const storage = getStorage()
      const storageRef = ref(
        storage,
        `${this.props.userId}/${this.props.data.id}/${file.name}`,
      )

      // upload file
      await uploadBytes(storageRef, file).then(() => {
        this.setState({
          uploadImageCount: i + 1,
        }, async () => {
          if (this.state.uploadImageCount === this.state.imagesToUpload) {
            await this.downloadAllImages()
            this.setState({
              uploadingImages: false,
              activePanel: 'photos',
            })
          }
        })
      })
    }
  }

  escapeTrack = (e) => {
    if (e.keyCode === 27) {
      this.props.closeTrack()
    }
  }

  activatePanel = (panelName) => {
    this.setState({
      activePanel: panelName,
    })
  }

  discardChangesAndCloseTrack = () => {
    // add validation to check if changes were made that were meant to be saved
    this.props.closeTrack()
  }

  render() {
    const data = get(this.props, 'data', {})
    const notes = get(this.props, 'data.notes', {})
    return (
      <If condition={this.props.isOpen}>
        <Styled.TrackWrapper>
          <Styled.Track>
            <Styled.CloseTrack>
              <Icon onClick={this.props.closeTrack} name="close" />
            </Styled.CloseTrack>
            <Styled.TrackContent>
              <>
                <TrackDetails
                  activePanel={this.state.activePanel}
                  activatePanel={this.activatePanel}
                  data={data}
                />
                <Choose>
                  <When condition={this.state.activePanel === 'photos'}>
                    <TrackPhotos
                      activatePanel={this.activatePanel}
                      uploadImages={this.uploadImages}
                      images={this.state.allImages}
                      downloadingImages={this.state.downloadingImages}
                      uploadingImages={this.state.uploadingImages}
                      imagesToUpload={this.state.imagesToUpload}
                      uploadImageCount={this.state.uploadImageCount}
                      updatePhotoLibraryData={this.props.updatePhotoLibraryData}
                    />
                  </When>
                  <When condition={this.state.activePanel === 'notes'}>
                    <TrackNotes
                      data={this.props.data}
                      tracksArray={this.props.tracksArray}
                      userId={this.props.userId}
                      notes={notes}
                    />
                  </When>
                  <Otherwise>
                    <TrackNotesPreview
                      activatePanel={this.activatePanel}
                      notes={notes}
                    />
                    <TrackPhotosPreview
                      downloadingImages={this.state.downloadingImages}
                      uploadingImages={this.state.uploadingImages}
                      activatePanel={this.activatePanel}
                      images={this.state.favImages}
                      uploadImages={this.uploadImages}
                    />
                    <TrackSpotifyPreview
                      tracks={this.props.tracksArray}
                      currentTrackId={this.props.data.id}
                      userId={this.props.userId}
                    />
                  </Otherwise>
                </Choose>
              </>
            </Styled.TrackContent>
          </Styled.Track>
        </Styled.TrackWrapper>
      </If>
    )
  }
}

export default Track
