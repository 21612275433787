

import styled, { css } from 'styled-components'

export const DetailsWrapper = styled.div(() => {
  return css`
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      margin: 0;

      &:nth-child(2) {
        margin: 0 8px;
      }
    }
  `
})

export const Divider = styled.div(() => {
  return css`
    background-color: white;
    height: 2px;
    width: 100%;
  `
})
