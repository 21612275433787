
import styled, { css } from 'styled-components'
import { colors } from '../../Assets/css-globals'

export const Button = styled.button((props) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${colors.primary};
    border: 2px solid ${colors.primary};
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-top: 18px;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
    }

    ${props.appearance === 'alt2' &&
    css`
      background-color: ${colors.alt1};
    `}

    ${props.disabled &&
    css`
      border-color: ${colors.alt1};
      color: ${colors.alt1};

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    `}

    ${props.isActive && css`
      background-color: rgba(0, 0, 0, 0.5);
    `}
  `
})

export const ButtonText = styled.div(() => {
  return css`
    display: flex;
  `
})

export const IconWrapper = styled.div(() => {
  return css`
    display: flex;
  `
})
