import styled, { css } from 'styled-components'

export const Wrapper = styled.div(() => {
  return css`
    display: block;
    width: 100%;
    height: 100vh;
    background-color: #555;
  `
})
