

import React from 'react'

import Icon from '../Icon'

import * as Styled from './Divider.style'

class LogoDivider extends React.Component {
  render() {
    return (
      <Styled.DetailsWrapper>
        <Styled.Divider />
        <Icon name="feetLogo" size={2} appearance="secondary" />
        <Styled.Divider />
      </Styled.DetailsWrapper>
    )
  }
}

export default LogoDivider
