import React from 'react'

import * as Styled from './NavBar.style'

export interface NavBarProps {
  isLoggedIn: boolean
}

class NavBar extends React.Component<NavBarProps> {

  public static defaultProps: Partial<NavBarProps> = {
    isLoggedIn: false
  }

  render() {
    if (!this.props.isLoggedIn) return null
    return (
      <Styled.NavContainer isLoggedIn={this.props.isLoggedIn}>
        <Styled.Logo href='/'>
          Tracks
        </Styled.Logo>
        <Styled.NavList>
          <ul>
            <li>activity</li>
            <li>friends</li>
            <li>tracks</li>
          </ul>
        </Styled.NavList>
        <Styled.ProfilePic href='/'>
          <img src="https://via.placeholder.com/50" alt="profile pic"></img>
        </Styled.ProfilePic>
      </Styled.NavContainer>
    )
  }
}

export default NavBar
