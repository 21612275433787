
const APIController = (function() {
    
  const clientId = process.env.REACT_APP_SPOTIFY_ID
  const clientSecret = process.env.REACT_APP_SPOTIFY_SECRET

  // private methods
  const _getToken = async () => {
      const result = await fetch('https://accounts.spotify.com/api/token', {
          method: 'POST',
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded', 
              'Authorization' : 'Basic ' + btoa(clientId + ':' + clientSecret)
          },
          body: 'grant_type=client_credentials'
      })

      const data = await result.json()
      return data.access_token
  }

  const _getTracks = async (token: string, tracksEndPoint: string) => {
    const limit = 10

    const result = await fetch(`${tracksEndPoint}&limit=${limit}`, {
        method: 'GET',
        headers: { 'Authorization' : 'Bearer ' + token}
    })
    
    const data = await result.json()
    return data.tracks.items
	}

	const _getTrack = async (token: string, trackEndPoint: string) => {

			const result = await fetch(`${trackEndPoint}`, {
					method: 'GET',
					headers: { 'Authorization' : 'Bearer ' + token}
			})

			const data = await result.json()
			return data
	}

	const _getIframeUrl = async (iframeEndPoint: string) => {

		const result = await fetch(`https://open.spotify.com/oembed?url=${iframeEndPoint}`, {
				method: 'GET',
		})

		const data = await result.json()
		return data
}

  return {
    getToken() {
        return _getToken()
    },
    getTracks(token: string, tracksEndPoint: string) {
      return _getTracks(token, tracksEndPoint)
    },
    getTrack(token: string, trackEndPoint: string) {
        return _getTrack(token, trackEndPoint)
    },
		getIframeUrl(iframeEndPoint: string) {
			return _getIframeUrl(iframeEndPoint)
		}
  }
})

export default APIController
