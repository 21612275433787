import styled, { css } from 'styled-components'

export const PhotosPreviewWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    padding-top: 26px;
  `
})

export const Header = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  `
})

export const Content = styled.div(() => {
  return css`
    display: flex;
    width: calc(100% + 12px);
    margin-left: -6px;
    height: 205px;
    overflow: hidden;

    > * {
      display: flex;
      width: 100%;
      margin: 0 6px;
    }
  `
})

export const LoadingAnimationWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `
})

export const Photo = styled.img(() => {
  return css`
    max-width: 100%;
    object-fit: cover;
    width: -webkit-fill-available;
  `
})
