import styled, { css } from 'styled-components'
import { NavBarProps } from './NavBar'

interface NavContainerProps {
  isLoggedIn: NavBarProps['isLoggedIn']
}

export const NavContainer = styled.div((props: NavContainerProps) => {
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    color: white;

    ${props.isLoggedIn && css`
      background-color: #222;
    `}
  `
})

export const Logo = styled.a(() => {
  return css`
    font-family: 'Averia Libre';
    font-size: 38px;
    text-decoration: none;
    color: #fff;
    margin-right: auto;
    padding-left: 20px;
  `
})

export const NavList = styled.div(() => {
  return css`
    display: flex;

    ul {
      display:flex;

      li {
        list-style: none;
        padding-right: 15px;

      }
    }
  `
})

export const ProfilePic = styled.a(() => {
  return css`
    height: 50px;
    padding: 0 20px;
  `
})
