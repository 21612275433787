import styled, { css } from 'styled-components'

import { colors } from '../../../../../../../Assets/css-globals'

export const PlaceholderWrapper = styled.label(() => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px dashed ${colors.primary};
    border-radius: 4px;
    color: ${colors.primary};
    font-style: initial;

    input {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  `
})
