

import styled, { css } from 'styled-components'

import { colors } from '../../Assets/css-globals'

interface Props {
  appearance: string,
  iconSize: number
}

export const IconButtonWrapper = styled.button((props: Props) => {
  return css`
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    ${
      props.appearance &&
      css`
        fill: ${colors[props.appearance]};
      `
    }

    ${
      props.iconSize &&
      props.iconSize === 1 &&
      css`
        max-width: 12px;
        max-height: 12px;
      `
    }
    ${
      props.iconSize &&
      props.iconSize === 2 &&
      css`
        max-width: 18px;
        max-height: 18px;
      `
    }
    ${
      props.iconSize &&
      props.iconSize === 3 &&
      css`
        max-width: 26px;
        max-height: 26px;
      `
    }
  `
})

export const Icon = styled.div((props: Props) => {
  return css`
    margin: 10px;

    div {
      width: 100%;
      height: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    ${
      props.appearance &&
      css`
        fill: ${colors[props.appearance]};
      `
    }

    ${
      props.iconSize &&
      props.iconSize === 1 &&
      css`
        max-width: 12px;
        max-height: 12px;
      `
    }
    ${
      props.iconSize &&
      props.iconSize === 2 &&
      css`
        max-width: 18px;
        max-height: 18px;
      `
    }
    ${
      props.iconSize &&
      props.iconSize === 3 &&
      css`
        max-width: 26px;
        max-height: 26px;
      `
    }
  `
})
