import { Component } from 'react'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, Auth, UserCredential } from 'firebase/auth'
import { setDoc, doc, getFirestore, Firestore } from 'firebase/firestore'

import { imgArray } from '../../Assets/login-bg-images'

import NavBar from '../../Components/NavBar'
import Divider from '../../Components/Divider'

import Button from '../../Components/Button/Button'
import Input from '../../Components/Input/Input'
import Logo from '../../Components/Logo/Logo'
import Link from '../../Components/Link/Link'

import * as Styled from './Login.style'

interface LoginScreenState {
  email: string
  password: string
  randomBgImg: string
}

class LoginScreen extends Component<{}, LoginScreenState> {
  auth: Auth
  firestore: Firestore

  constructor(props) {
    super(props)
    this.state = {
      randomBgImg: '',
      email: '',
      password: '',
    }

    this.auth = getAuth()
    this.firestore = getFirestore()
  }

  componentDidMount() {
    this.randomImgSelect()
  }

  randomImgSelect = () => {
    this.setState({
      randomBgImg: imgArray[Math.floor(Math.random() * 5)],
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  login = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signInWithEmailAndPassword(this.auth, this.state.email, this.state.password)
      .then((u) => {})
      .catch((error) => {
        console.error(error)
      })
  }

  signup = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let newUser = {} as UserCredential

    try {
      newUser = await createUserWithEmailAndPassword(this.auth, this.state.email, this.state.password)
      console.log('New user created: ', newUser)
    } catch (error) {
      console.log('Error creating new user', error)
    }

    try {
      console.log('Creating empty tracks database for user: ', newUser.user.uid)
      await setDoc(doc(this.firestore, 'users', newUser.user.uid), {
        tracks: [],
      })
    } catch (error) {
      console.log(`Error creating empty tracks for user: ${newUser.user.uid}`, error)
    }
  }

  render() {
    return (
      <Styled.PageWrapper>
        <Styled.BackgroundImage image={this.state.randomBgImg}>
          <NavBar />
          <Styled.LoginCardContainer>
            <Styled.LoginCard>
              <Styled.Intro>
                <Logo />
                <Styled.TagLine>
                  A new way to record and share <br /> your adventures
                </Styled.TagLine>
                <Divider />
              </Styled.Intro>
              <Styled.LoginForm>
                <span>Login or register a new account below</span>
                <div>
                  <Input
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                </div>
                <div>
                  <Input
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <Styled.SubText>
                  Can't remember your password?
                  <Styled.PasswordLink>
                    <Link text="Click here" href="#" />
                  </Styled.PasswordLink>
                  to reset.
                </Styled.SubText>
                <Button type="submit" text="Login" onClick={this.login} />
                <Button type="submit" text="Signup" onClick={this.signup} />
              </Styled.LoginForm>
            </Styled.LoginCard>
          </Styled.LoginCardContainer>
        </Styled.BackgroundImage>
      </Styled.PageWrapper>
    )
  }
}

export default LoginScreen
