import { Component, ReactNode } from 'react'

import LoadingAnimation from '../../Components/LoadingAnimation/LoadingAnimation'

import * as Styled from './LoadingScreen.style'

export default class LoadingScreen extends Component {
  
  render(): ReactNode {
    return (
      <Styled.LoadingScreen>
        <LoadingAnimation />
      </Styled.LoadingScreen>
    )
  }
}
