import styled, { css } from 'styled-components'

import { colors } from '../../../../Assets/css-globals'

export const PinWrapper = styled.button(() => {
  return css`
    cursor: pointer;
    height: 0;
    width: 0;
    border: 0;
    padding: 0;
    
    &:focus {
      outline: none;
    },
  `
})

interface PinIconProps {
  scale: number
}
export const PinIcon = styled.img((props: PinIconProps) => {
  return css`
    position: relative;
    height: ${props.scale}px;
    bottom: 15px;
    right: 10px;
    webkit-transition: bottom 0.1s ease-in-out;
    moz-transition: bottom 0.1s ease-in-out;
    o-transition: bottom 0.1s ease-in-out;
    transition: bottom 0.1s ease-in-out;

    &:hover {
      bottom: 18px;
      padding-bottom: 18px;
      fill: ${colors.primary};
      webkit-transition: bottom 0.1s ease-in-out;
      moz-transition: bottom 0.1s ease-in-out;
      o-transition: bottom 0.1s ease-in-out;
      transition: bottom 0.1s ease-in-out;
    }
  `
})
