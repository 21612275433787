

import styled, { css } from 'styled-components'
import { colors } from '../../../../../../Assets/css-globals'

export const NotesWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `
})

export const Header = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  `
})

export const Content = styled.div((props) => {
  return css`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #ccc;

    textarea {
      border-radius: 4px;
      height: 100%;
      padding: 12px;
      border: 1px solid transparent;
      background-color: ${colors.alt1};
      color: ${colors.secondary};
      resize: none;

      &:focus {
        outline: none;
        border: 1px solid ${colors.alt2};
      }
    }
  `
})

export const SubmitWrapper = styled.div(() => {
  return css`
    button {
      width: 150px;
      margin-left: auto;
    }
  `
})

// SCROLLBAR STYLING

// ::-webkit-scrollbar-track: {
//   border: 1px solid #666;
//   background-color: #999;
//   border-radius: 6px;
//   webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
// };
// ::-webkit-scrollbar: {
//   width: 7px;
//   background-color: transparent;
// };
// ::-webkit-scrollbar-thumb: {
//   border-radius: 10px;
//   webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//   background-color: #999;
//   border: 1px solid #666;
// },
