import styled, { css } from 'styled-components'

export const Input = styled.input(() => {
  return css`
    /* color: #fff;
    background-color: rgba(255, 255, 255, 0.1); */
    font-size: inherit;
    font-family: inherit;
    border-bottom-color: rgba(255, 255, 255, 0);
    border: 1px solid transparent;
    padding-left: 12px;
    width: calc(100% - 14px);
    height: 38px;
  `
})
