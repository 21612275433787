import styled, { css } from 'styled-components'

export const TrackWrapper = styled.div(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: calc(100% - 40px);
    background-color: rgba(0, 0, 0, 0.4);
  `
})

export const Track = styled.div(() => {
  return css`
    position: relative;
    width: 100%;
    max-width: 700px;
    height: 100%;
    /* overflow: auto; */
    max-height: 612px;
    background-color: #222;
    border-radius: 8px;
    -webkit-box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.5);
  `
})

export const CloseTrack = styled.div(() => {
  return css`
    position: absolute;
    right: 15px;
    top: 15px;

    > button {
      position: relative;
      align-self: flex-end;
      margin-left: 14px;
    }
  `
})

export const TrackContent = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    overflow: auto;
  `
})

export const LoadingAnimation = styled.div(() => {
  return css`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: red;
  `
})

// SCROLLBAR STYLING

// &::-webkit-scrollbar-track: {
//   border: 1px solid #666;
//   background-color: #444;
//   border-radius: 6px;
//   webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
// &::-webkit-scrollbar: {
//   width: 7px;
//   background-color: transparent;
// }
// &::-webkit-scrollbar-thumb: {
//   border-radius: 10px;
//   webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #666;
//   border: 1px solid #666;
// }
