
import React from 'react'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import SpotifyEmbed from '../SpotifyEmbed/SpotifyEmbed'
import SongSearchBar from '../SongSearchBar/SongSearchBar'

import { SpotifySongData, TrackData } from '../../Track.types'

import * as Styled from './TrackSpotifyPreview.style'

// import express from 'express'

interface TrackSpotifyPreviewProps {
  tracks: TrackData[]
  currentTrackId: string
  userId: string
}

interface TrackSpotifyPreviewState {
  spotifyLogin: string
}

class TrackSpotifyPreview extends React.Component<TrackSpotifyPreviewProps, TrackSpotifyPreviewState> {

  state = {
    spotifyLogin: 'hi',
  }

  // componentDidMount() {
  //   const url = new URL(window.location.href)
  //   const { hash } = url

  //   if (hash.includes('access_token')) {
  //     const hashData = this.hashData(hash)

  //     localStorage.setItem('spotify_access_token', hashData['access_token'])
  //   }
  // }

  fetchIframeUrl = (): SpotifySongData['iframeUrl'] | undefined => {
    let iframeUrl
    this.props.tracks.filter((track) => {
      if (track.id === this.props.currentTrackId) {
        iframeUrl = track.playlist && track.playlist[0]?.iframeUrl
      }
    })
    return iframeUrl
  }

  // spotifyLoginButton = async () => {
  //   const clientId = `client_id=${process.env.REACT_APP_SPOTIFY_ID}`
  //   const responseType = 'response_type=token'
  //   const redirectUri = 'redirect_uri=http://localhost:3000/'
  //   const state = 'state=1234567890123456'
  //   const scope = 'scope=streaming app-remote-control'
  //   const authUrl = `https://accounts.spotify.com/authorize?${clientId}&${responseType}&${redirectUri}&${state}&${scope}`

  //   const res = await fetch(authUrl, {
  //     method: 'GET',
  //     mode: 'no-cors',
  //   })

  //   console.log('res', res)

  //   // window.location = res
  // }

  // spotifyLogin = () => {
  //   const clientId = `client_id=${process.env.REACT_APP_SPOTIFY_ID}`
  //   const responseType = 'response_type=token'
  //   // const redirectUri = 'redirect_uri=https://tracksapp.uk'
  //   const redirectUri = 'redirect_uri=http://localhost:3000/'
  //   const state = 'state=1234567890123456'
  //   // const scope = 'scope=user-read-private user-read-email'
  //   const scope = 'scope=streaming app-remote-control'
  //   const authUrl = `https://accounts.spotify.com/authorize?${clientId}&${responseType}&${redirectUri}&${state}&${scope}`

  //   return authUrl
  // }

  // hashData = (hash: string) => {
  //   const responseData = hash.substring(1)
  //   const splitData = responseData.split('&')
  //   const authObj = splitData.reduce((prevData, newData) => {
  //     const [key, value] = newData.split('=')
  //     prevData = prevData ?? {}
  //     prevData[key] = value
  //     return prevData
  //   }, {} as Record<string, string>)
    
  //   return authObj
  // }

  // #access_token=BQCsvykqXshXug8m8iUzOD9N39u0gzFffwn4E1Nu5N7txYomWiGvl-StT3BZSTIp56fqCHBALwelpGvOTeFrfaFlbJdTKjHWceiufGWNvOffJkDCbOlgAWpQtDzblR26IZdRWia2PXREyFnlgSbA6NvZR190rgjpHk_4KExL0o-JjzX8a8Usd7qxx0sMPg&token_type=Bearer&expires_in=3600&state=1234567890123456


  render() {
    const iframeUrl = this.fetchIframeUrl()
    return (
      <Styled.SpotifyPreviewWrapper>
        <Styled.Header>
          <h5>Playlist</h5>
        </Styled.Header>
        {/* <a href={this.spotifyLogin()}>
          spotify
        </a>
        <button onClick={this.spotifyLoginButton}>hihi</button> */}
        <Choose>
          <When condition={iframeUrl}>
            <SpotifyEmbed iframeUrl={iframeUrl!.html}/>
          </When>
          <Otherwise>
            <SongSearchBar
              tracks={this.props.tracks}
              currentTrackId={this.props.currentTrackId}
              userId={this.props.userId}
              // accessId={this.accessId(this.state.urlHash)}
            />
          </Otherwise>
        </Choose>
      </Styled.SpotifyPreviewWrapper>
    )
  }
}

export default TrackSpotifyPreview
