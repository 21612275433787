

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import * as Styled from './PhotoLibraryModal.style'

class PhotoLibraryModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photoKey: this.props.photoKey,
    }
    this.numberOfPhotos = this.props.photos.length
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false)
  }

  prevPhoto = () => {
    if (this.state.photoKey === 0) {
      this.setState({
        photoKey: this.numberOfPhotos - 1,
      })
      return
    }
    this.setState({
      photoKey: this.state.photoKey - 1,
    })
  }

  nextPhoto = () => {
    if (this.state.photoKey === this.numberOfPhotos - 1) {
      this.setState({
        photoKey: 0,
      })
      return
    }
    this.setState({
      photoKey: this.state.photoKey + 1,
    })
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.props.closePhotoLibrary()
    }
    if (e.keyCode === 37) {
      this.prevPhoto()
    }
    if (e.keyCode === 39) {
      this.nextPhoto()
    }
  }

  render() {
    return (
      <Styled.LibraryWrapper>
        <Styled.CloseButton>
          <Icon name="close" size={2} onClick={this.props.closePhotoLibrary} />
        </Styled.CloseButton>
        <Styled.PhotoLibrary>
          <Styled.PhotoWrapper>
            <Styled.PrevPhoto>
              <Icon name="rightArrow" size={3} onClick={this.prevPhoto} />
            </Styled.PrevPhoto>
            <Styled.Photo>
              <img
                alt={this.props.photos[this.state.photoKey].name}
                src={this.props.photos[this.state.photoKey].url}
              />
            </Styled.Photo>
            <Styled.NextPhoto>
              <Icon name="rightArrow" size={3} onClick={this.nextPhoto} />
            </Styled.NextPhoto>
          </Styled.PhotoWrapper>
          <Styled.PhotoCount>
            {`[ ${this.state.photoKey + 1} / ${this.numberOfPhotos} ]`}
          </Styled.PhotoCount>
        </Styled.PhotoLibrary>
      </Styled.LibraryWrapper>
    )
  }
}

PhotoLibraryModal.propTypes = {
  // closePhotoLibrary - cb to set openModal state in TrackPhotos to false
  closePhotoLibrary: PropTypes.func,
  // Photos
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      // Alt
      name: PropTypes.string,
      // URL location of file
      url: PropTypes.string,
    }),
  ),
  // PhotoKey - the key of the current photo being displayed
  photoKey: PropTypes.number,
}

PhotoLibraryModal.defaultProps = {
  openModal: false,
}

export default PhotoLibraryModal
