import close from './close.svg'
import rightArrow from './right-arrow.svg'
import pin from './pin.svg'
import home from './home.svg'
import photos from './photos.svg'
import blog from './blog.svg'
import plus from './plus-circle.svg'
import feetLogo from './foot-logo.svg'
import footLeft from './foot-left.svg'
import footRight from './foot-right.svg'
import pointer from './pointer.svg'

const icons = {
  close,
  rightArrow,
  pin,
  home,
  photos,
  blog,
  plus,
  feetLogo,
  footLeft,
  footRight,
  pointer
}

export { icons }
