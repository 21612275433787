import styled, { css } from 'styled-components'

export const PhotoWrapper = styled.div(() => {
  return css`
    position: relative;

    > a {
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      > div {
        display: block;
      }
    }
  `
})

export const DeletePhoto = styled.div(() => {
  return css`
    display: none;
    position: absolute;
    top: 12px;
    right: 12px;

    > div {
      margin: 0;
    }
  `
})

export const Photo = styled.div(() => {
  return css`
    img {
      display: flex;
      max-width: 100%;
      width: -webkit-fill-available;
      height: 100%;
      object-fit: cover;
    }
  `
})
