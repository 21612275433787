

import React from 'react'
import PropTypes from 'prop-types'

import * as Styled from './Input.style'

class Input extends React.Component {
  handleOnChange = (e) => {
    e.preventDefault()
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  handleOnBlur = (e) => {
    e.preventDefault()
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  handleOnFocus = (e) => {
    e.preventDefault()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  render() {
    return (
      <Styled.Input
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
        type={this.props.type}
        name={this.props.name}
        placeholder={this.props.placeholder}
        value={this.props.value}
      />
    )
  }
}

Input.propTypes = {
  // Type - defaults to 'text'
  type: PropTypes.string,
  // OnChange - function to call onChange
  onChange: PropTypes.func,
  // Name
  name: PropTypes.string,
  // Placeholder
  placeholder: PropTypes.string,
  // Value
  value: PropTypes.string,
}

Input.defaultProps = {
  type: 'text',
}

export default Input
