import styled, { css } from 'styled-components'

export const SpotifyPreviewWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    padding-top: 26px;
  `
})

export const Header = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  `
})

export const SearchWrapper = styled.div(() => {
  return css`
    display: flex;

    button {
      width: 42px;
      margin-left: 14px;
      margin-top: 0px;
    }
  `
})
