
import { Component } from 'react'

// import Button from '../../Button/Button'
import Divider from '../../../../Divider'
import Icon from '../../../../Icon'

import * as Styled from './TrackDetails.style'
import { TrackData } from '../Track.types'

interface TrackDetailsProps {
  activePanel: string
  activatePanel: (panel: string) => {}
  data: Partial<TrackData>
}

class TrackDetails extends Component<TrackDetailsProps> {
  getAppearance = (panelName) => {
    if (this.props.activePanel === panelName) {
      return 'secondary'
    }
    return 'primary'
  }

  render() {
    if (!this.props.data) return null

    const { startDate, endDate, locationName } = this.props.data
    return (
      <Styled.DetailsWrapper>
        <Styled.DetailsContent>
          <Styled.ContentNav activePanel={this.props.activePanel}>
            <Icon
              onClick={() => {
                this.props.activatePanel('home')
              }}
              name="home"
              size={2}
              appearance={this.getAppearance('home')}
            />
            <Icon
              onClick={() => {
                this.props.activatePanel('notes')
              }}
              name="blog"
              size={2}
              appearance={this.getAppearance('notes')}
            />
            <Icon
              onClick={() => {
                this.props.activatePanel('photos')
              }}
              name="photos"
              size={2}
              appearance={this.getAppearance('photos')}
            />
          </Styled.ContentNav>
          {/* prev track button */}
          {/* <Button /> */}
          <Styled.TitleAndDates>
            <h4>{locationName}</h4>
            <Styled.Dates>
              {startDate} to {endDate}
            </Styled.Dates>
          </Styled.TitleAndDates>
          {/* next track button */}
          {/* <Button /> */}
        </Styled.DetailsContent>
        <Divider />
      </Styled.DetailsWrapper>
    )
  }
}

export default TrackDetails
