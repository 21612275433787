import { Component, ReactNode } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { Choose, When, Otherwise } from 'jsx-control-statements'

import Login from './Pages/Login/'
import LoadingScreen from './Pages/LoadingScreen/'

import NavBar from './Components/NavBar'
import WorldMap from './Components/WorldMap'

import * as Styled from './App.style'

interface AppState {
  user: unknown | null
	isLoading: boolean
}

type AppProps = Record<string, unknown>

export default class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {

    super(props)

    this.state = {
      isLoading: true,
			user: null
    }

    const firebaseConfig = {
      authDomain: 'tracks-7eb30.firebaseapp.com',
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      databaseURL: 'https://tracks-7eb30.firebaseio.com',
      projectId: 'tracks-7eb30',
      storageBucket: 'tracks-7eb30.appspot.com',
      messagingSenderId: '329935962622',
      appId: '1:329935962622:web:eda0842c5820f809',
    }

    // Initialize Firebase
    initializeApp(firebaseConfig)
  }

  componentDidMount() {
    this.authListener()
  }

  authListener() {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ 
          user,
          isLoading: false,
        })
      } else {
				this.setState({ 
          isLoading: false,
        })
			}
    })
  }
  
  render(): ReactNode {
    return (
      <Choose>
        <When condition={this.state.isLoading}>
          <LoadingScreen />
        </When>
        <When condition={!this.state.isLoading && this.state.user === null}>
          <Login />
        </When>
        <Otherwise>
          <Styled.Wrapper>
            <NavBar isLoggedIn={this.state.user ? true : false} />
            <WorldMap />
          </Styled.Wrapper>
        </Otherwise>
      </Choose>
    )
  }
}
