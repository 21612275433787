import styled, { css } from 'styled-components'

export const PhotosWrapper = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `
})

export const Header = styled.div(() => {
  return css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  `
})

export const Content = styled.div(() => {
  return css`
    display: flex;
    width: calc(100% + 12px);
    margin-left: -6px;
    height: 444px;
    overflow: auto;
    flex-wrap: wrap;

    > * {
      display: flex;
      flex-basis: calc(33% - 10px);
      height: 205px;
      margin: 0 6px 12px;
    }
  `
})

export const ProgressBar = styled.div(() => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `
})
