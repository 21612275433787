
import React from 'react'

import Icon from '../../../../../../Icon'

import * as Styled from './PlaceholderPhoto.style'

class PlaceholderPhoto extends React.Component {
  render() {
    return (
      <Styled.PlaceholderWrapper onClick={this.props.onClick}>
        <input
          type="file"
          multiple
          name="files"
          id="uploadImagesButton"
          onChange={this.props.onChange}
        ></input>
        <div>
          <Icon name="plus" appearance="primary" size={2} />
        </div>
        Add Photos
      </Styled.PlaceholderWrapper>
    )
  }
}

export default PlaceholderPhoto
