

import styled, { css } from 'styled-components'
import { colors } from '../../Assets/css-globals'

export const Link = styled.a((props) => {
  return css`
    font-size: 14px;
    font-style: italic;
    color: ${colors.primary};

    ${!props.isUnderlined &&
    css`
      text-decoration: none;
    `}
  `
})
