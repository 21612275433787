
import { Component } from 'react'
import { Choose, When, Otherwise } from 'jsx-control-statements'
import { ReactSVG } from 'react-svg'

import { icons } from '../../Assets/Icons'

import * as Styled from './Icon.style'

interface IconProps {
  onClick: () => void
  appearance: string
  size: number
  name: string
}

class Icon extends Component<IconProps> {
  public static defaultProps: Partial<IconProps> = {
    appearance: 'primary',
    size: 1
  }

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    return (
      <Choose>
        <When condition={this.props.onClick}>
          <Styled.IconButtonWrapper
            appearance={this.props.appearance}
            onClick={this.onClick}
            iconSize={this.props.size}
          >
            <ReactSVG src={icons[this.props.name]} />
          </Styled.IconButtonWrapper>
        </When>
        <Otherwise>
          <Styled.Icon
            appearance={this.props.appearance}
            iconSize={this.props.size}
          >
            <ReactSVG src={icons[this.props.name]} />
          </Styled.Icon>
        </Otherwise>
      </Choose>
    )
  }
}

export default Icon
