

import React from 'react'

import * as Styled from './Logo.style'

class Logo extends React.Component {
  render() {
    return <Styled.Logo>Tracks</Styled.Logo>
  }
}

export default Logo
